import { AxiosResponse } from 'axios'
import axios from '../instance'
import { GetInboxParams, GetMessageByIdParams, GetOrderHistoryByIdParams } from './chat.type'

export const getPageInShopChat = async (shopId: string): Promise<any> => {
	try {
		const response = await axios.get(`/sync-chat/shop/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getInboxList = async (params: GetInboxParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/sync-chat/inbox', {
			...params
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getMessageByInboxId = async (payload: GetMessageByIdParams): Promise<AxiosResponse> => {
	const { _conversationId, limit, afterToken, pageId } = payload
	try {
		const response = await axios.get('/sync-chat/message', {
			params: {
				_conversationId,
				pageId,
				limit,
				afterToken,
			}
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const sendMessageToPage = async ({ senderId, message, pageId, messageId }: any): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/sync-chat/message/text', { senderId, message, pageId, messageId })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getOrderHistoryById = async ({ inboxId, limit = 10, offset = 1 }: GetOrderHistoryByIdParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/sync-chat/order-inbox', {
			params: {
				inboxId,
				limit,
				offset,
			}
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateUnreadInbox = async (inboxId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.put('/sync-chat/message/read', { inboxId })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const sendFileUpload = async (formData: FormData): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/sync-chat/message/attachments', formData)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchPageSetting = async (pageId: string): Promise<AxiosResponse> => {
	try {
		const response = await axios.get(`/fanpage/detail/${pageId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export type PageSettingPayload = {
	notifyTrackingMessage: string,
	favoriteMessages: string[]
}

export const updatePageSetting = async (pageId: string, settingPayload: PageSettingPayload): Promise<AxiosResponse> => {
	try {
		const response = await axios.put(`/fanpage/setting-config/${pageId}`, settingPayload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateChatTag = async (inboxId: string, tags: string[]): Promise<AxiosResponse> => {
	try {
		const response = await axios.put('/sync-chat/tag/update', {
			inboxId,
			tags
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
