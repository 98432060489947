import axios from '../instance'
import { CreateLivePayload, DeleteStatusRevokePage, EditLivePayload, FilterLivePayload, GetLiveCommentParams, GetOrderParams, ImportPagePayload, Product } from './live.type'

// Old API
export const getPageInShop = async (shopId: string): Promise<any> => {
	try {
		const response = await axios.get(`/fanpage/shop/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getPageInShopLive = async (shopId: string): Promise<any> => {
	try {
		const response = await axios.get(`/live/shop/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getImportFanPage = async (shopId: string, facebookToken: string): Promise<any> => {
	try {
		const response = await axios.post('/shop/get-import-fanpages', {
			shopId,
			facebookToken,
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const importPageToShop = async (payload: ImportPagePayload): Promise<any> => {
	try {
		const response = await axios.post('/fanpage/create', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getLiveListInPage = async (payload: FilterLivePayload): Promise<any> => {
	try {
		const response = await axios.get(`/live/page/${payload.pageId}`, {
			params: { ...payload }
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deletePageFromShop = async (pageMongoId: string): Promise<any> => {
	try {
		const response = await axios.delete(`/fanpage/delete/${pageMongoId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const createLive = async (payload: CreateLivePayload): Promise<any> => {
	try {
		const response = await axios.post('/live/create', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getLiveDetail = async (liveId: string): Promise<any> => {
	try {
		const response = await axios.get(`/live/${liveId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const editLive = async (liveId: string, editLivePayload: EditLivePayload): Promise<any> => {
	try {
		const response = await axios.put(`/live/${liveId}/update-live`, editLivePayload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const editLiveProduct = async (liveId: string, products: Product[]): Promise<any> => {
	try {
		const response = await axios.put(`/live/${liveId}/update-product`, { products })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const checkLiveStatus = async (pageId: string, liveId: string): Promise<any> => {
	try {
		const response = await axios.get(`/live/check-live-status/${pageId}`, {
			params: { liveId }
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const startLive = async (liveStreamId: string, liveId: string, videoId: string, title: string): Promise<any> => {
	try {
		const response = await axios.post('/live/start-live', {
			liveId,
			videoId,
			title,
			liveStreamId,
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const restartLive = async (liveStreamId: string, liveId: string, videoId: string, title: string): Promise<any> => {
	try {
		const response = await axios.post('/live/restart-live', {
			liveId,
			videoId,
			title,
			liveStreamId,
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getLiveComment = async (liveId: string, { limit = 25, offset = 1 }: GetLiveCommentParams): Promise<any> => {
	try {
		const response = await axios.get(`/live/${liveId}/comments`, {
			params: {
				limit,
				offset
			}
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getLiveOrder = async (liveId: string, { limit = 25, offset = 1, status }: GetOrderParams): Promise<any> => {
	try {
		const response = await axios.get(`/live/${liveId}/orders`, {
			params: {
				limit,
				offset,
				status
			}
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const endLive = async (liveId: string): Promise<any> => {
	try {
		const response = await axios.post(`/live/${liveId}/end-live`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteOrderInLive = async (orderId: string): Promise<any> => {
	try {
		const response = await axios.delete(`/live/delete/order/${orderId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteLive = async (liveId: string): Promise<any> => {
	try {
		const response = await axios.delete(`/live/${liveId}/delete`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const revokePage = async (shopId:string): Promise<DeleteStatusRevokePage> => {
	try {
		const response = await axios.post('/fanpage/revoke', {shopId})
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}