import { defineStore } from 'pinia'
import { CurrentPage, State } from '@/pinia/facebook-page/types'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { getImportFanPage, getPageInShopLive, getPageInShop } from '@/api/live/live.api'
import { getPageInShopMent } from '@/api/mentreply/mentreply.api'
import { getPageInShopChat } from '@/api/chat/chat.api'

export const useFacebookPageStore = defineStore('facebook-page', {
	persist: {
		paths: ['shopPageList', 'pageList', 'selectedPages'],
	},
	state: (): State => ({
		shopPageList: [],
		pageList: [],
		selectedPages: [],
		
		// expired
		expired: null,
		isRevoke: false,
	}),
	getters: {
		findPageByPageId: (state: State) => (pageId: string) => {
			const findPage = state.shopPageList.find((page) => page.pageId === pageId)
			return findPage
		},
	},
	actions: {
		//
		updatePageList(pageList: []) {
			this.pageList = pageList
		},
		updateSelectedPage(selectedPages: []) {
			this.selectedPages = selectedPages
		},
		updateShopPageList(shopPageList: []) {
			this.shopPageList = shopPageList
		},
		async getAllpage(facebookToken: string) {
			try {
				const shopStore = useShopStore()
				const shopId = shopStore.selectedShopId as string
                
				const { data } = await getImportFanPage(shopId, facebookToken)
                
				this.updatePageList(data.availablePages)
    
				return data
			} catch (error) {
				return Promise.reject(error)
			}
		},
		async getAllPageInShop() {
			try {
				const shopStore = useShopStore()
				const shopId = shopStore.selectedShopId as string

				const { data: pageInShop } = await getPageInShop(shopId)
				this.updateShopPageList(pageInShop)
			} catch (error) {
				return Promise.reject(error)
			}
		},
		async getAllPageInShopLive() {
			try {
				const shopStore = useShopStore()
				const shopId = shopStore.selectedShopId as string

				const { data: { pages: pageInShop, expired, isRevoke }} = await getPageInShopLive(shopId)
				this.updateShopPageList(pageInShop)
				this.expired = expired
				this.isRevoke = isRevoke
			} catch (error) {
				return Promise.reject(error)
			}
		},
		async getAllPageInShopChat() {
			try {
				const shopStore = useShopStore()
				const shopId = shopStore.selectedShopId as string

				const { data: { pages: pageInShop, expired, isRevoke }} = await getPageInShopChat(shopId)
				this.updateShopPageList(pageInShop)
				this.expired = expired
				this.isRevoke = isRevoke
			} catch (error) {
				return Promise.reject(error)
			}
		},
		async getAllPageInShopMent() {
			try {
				const shopStore = useShopStore()
				const shopId = shopStore.selectedShopId as string

				const { data: { pages: pageInShop, expired, isRevoke } } = await getPageInShopMent(shopId)
				this.updateShopPageList(pageInShop)
				this.expired = expired
				this.isRevoke = isRevoke
			} catch (error) {
				return Promise.reject(error)
			}
		},
		updateShopPageListUnreadByPageId(pageId: string, unreadTotal: number) {
			this.shopPageList = this.shopPageList.map((page: CurrentPage) => {
				if (page._id === pageId) {
					return {
						...page,
						unreadTotal
					}
				}
				return page
			})
		}
	}
}) 