import axios from '../instance'
import {
	CreatePayload,
	EditPayload,
	FetchPostInPageRequest,
	FetchRequest,
	PostDetailResponse,
	ICheckPostDupRequest,
} from './type'

export const getPageInShopMent = async (shopId: string): Promise<any> => {
	try {
		const response = await axios.get(`/post-reply/shop/${shopId}`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchPostReplyList = async (
	params: FetchRequest
): Promise<any> => {
	try {
		const response = await axios.get('/post-reply', {
			params,
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchPostInPage = async (
	params: FetchPostInPageRequest
): Promise<any> => {
	try {
		const response = await axios.get('/post-reply/post', { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const postReplyCreate = async (payload: CreatePayload): Promise<any> => {
	try {
		const response = await axios.post('/post-reply/create', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const postReplyEdit = async (
	payload: Omit<EditPayload, 'pageId'>
): Promise<any> => {
	try {
		const response = await axios.put('/post-reply/edit', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchPostDetail = async (
	postId: string
): Promise<PostDetailResponse> => {
	try {
		const { data } = await axios.get<PostDetailResponse>(
			`/post-reply/${postId}`
		)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateEnablePost = async (postId: string): Promise<any> => {
	try {
		const { data } = await axios.patch(
			`/post-reply/update-enable/${postId}`
		)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteMentreplyPost = async (postId: string): Promise<any> => {
	try {
		const { data } = await axios.delete(`/post-reply/delete/${postId}`)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const checkPostDuplicate = async (
	checkPostDuplicatePayload: ICheckPostDupRequest
): Promise<any> => {
	try {
		const { data } = await axios.post(
			'/post-reply/check-duplicate',
			checkPostDuplicatePayload
		)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}
